import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ConfigProvider } from 'antd';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import './index.css';
import Contexts from './contexts/Contexts';

// Create a client
const queryClient = new QueryClient();

const isDevServer =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

const theme = createTheme({
  palette: {
    primary: {
      main: '#820014',
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Contexts>
      <Router>
        <QueryClientProvider client={queryClient}>
          {isDevServer && <ReactQueryDevtools initialIsOpen={false} />}
          <ConfigProvider
            theme={{
              token: {
                // Seed Token
                colorPrimary: '#820014',
              },
            }}
          >
            <ThemeProvider theme={theme}>
              <App />
            </ThemeProvider>
          </ConfigProvider>
        </QueryClientProvider>
      </Router>
    </Contexts>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
