import { useQuery } from '@tanstack/react-query';

import { getAsset, getAssetsForProduct, getGalleryAssets } from 'api/assets';

export const useGetAsset = (id?: string) => {
  return useQuery({
    queryKey: [getAsset.name, id],
    queryFn: async (): Promise<string> => {
      return (await getAsset(id!)).data;
    },
    enabled: !!id,
  });
};

export const useGetAssetsForProduct = (articleNumber?: string) => {
  return useQuery({
    queryKey: [getAssetsForProduct.name, articleNumber],
    queryFn: async (): Promise<Map<string, string>> => {
      const data =(await getAssetsForProduct(articleNumber!)).data;
      return new Map(Object.entries(data));
    },
    enabled: !!articleNumber,
  });
};

export const useGetGalleryAssets = () => {
  return useQuery({
    queryKey: [getGalleryAssets.name],
    queryFn: async (): Promise<Map<string, string>> => {
      const data =(await getGalleryAssets()).data;
      return new Map(Object.entries(data));
    },
    //enabled: !!articleNumber,
  });
};
