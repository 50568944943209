import axios, { AxiosResponse } from 'axios';

import { ENDPOINT_BASE } from 'constants/index';
import { CreateOrderRequest, CreateOrderResponse } from 'api/types';

const ORDER_PATH = 'order';
const baseURL = `${ENDPOINT_BASE}${ORDER_PATH}`;

const client = axios.create({
  baseURL,
});

export async function createOrder(
  request: CreateOrderRequest
): Promise<AxiosResponse<CreateOrderResponse>> {
  const response = await client.post<CreateOrderResponse>('', request);

  return response;
}
