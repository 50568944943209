import {
  useMutation,
  UseMutateFunction,
  useQueryClient,
} from '@tanstack/react-query';
import { message } from 'antd';

import { createOrder } from 'api/order';
import { getCart } from 'api/cart';
import { CreateOrderRequest } from 'api/types';

export const useCreateOrder = (cb?: () => void): [
  UseMutateFunction<void, Error, CreateOrderRequest, unknown>,
  'error' | 'idle' | 'pending' | 'success',
] => {
  const queryClient = useQueryClient();

  const {
    mutate,
    status,
  }: {
    mutate: UseMutateFunction<void, Error, CreateOrderRequest, unknown>;
    status: 'error' | 'idle' | 'pending' | 'success';
  } = useMutation({
    mutationFn: async (request: CreateOrderRequest): Promise<void> => {
      await createOrder(request);
    },
    onError: async () => {
      message.error('Something went wrong. Error creating order.');
      const cartId = sessionStorage.getItem('cartId');
      sessionStorage.removeItem('cartId');
      await queryClient.invalidateQueries({ queryKey: [getCart.name, cartId] });
    },
    onSuccess: () => {
      sessionStorage.removeItem('cartId');
      message.success('Order successfuly submited. We will contact you soon!', 10_000);
      if (cb) {
        cb();
      }
    },
  });

  return [mutate, status];
};
