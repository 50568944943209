import axios, { AxiosResponse } from 'axios';

import { ENDPOINT_BASE } from 'constants/index';

const ASSET_PATH = 'asset/';
const baseUrlAsset = `${ENDPOINT_BASE}${ASSET_PATH}`;
const PRODUCT_PATH = 'product';
const GALLERY_PATH = 'gallery';

const client = axios.create({
  baseURL: baseUrlAsset,
});

export async function getAsset(id: string): Promise<AxiosResponse<string>> {
  return await client.get<string>(id);
}

export async function getAssetsForProduct(
  productId: string
): Promise<AxiosResponse<Map<number, string>>> {
  return await client.get<Map<number, string>>(`/${PRODUCT_PATH}/${productId}`);
}

export async function getGalleryAssets(

): Promise<AxiosResponse<Map<number, string>>> {
  return await client.get<Map<number, string>>(`/${GALLERY_PATH}`);
}

