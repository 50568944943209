import axios, { AxiosResponse } from 'axios';
import { GetProductResponse, GetProductsResponse } from 'api/types';

import { ENDPOINT_BASE } from 'constants/index';

const PRODUCT_PATH = 'product/';
const baseUrlProduct = `${ENDPOINT_BASE}${PRODUCT_PATH}`;

const client = axios.create({
  baseURL: baseUrlProduct,
});

export async function getProducts(): Promise<
  AxiosResponse<GetProductsResponse>
> {
  const response = await client.get<GetProductsResponse>('');

  return response;
}
export async function getProduct(
  id: string
): Promise<AxiosResponse<GetProductResponse>> {
  const response = await client.get<GetProductResponse>(id);

  return response;
}
