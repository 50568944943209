import React from 'react';

import { SocialIcon } from 'react-social-icons/component';
import 'react-social-icons/instagram';
import 'react-social-icons/tiktok';

import { Typography } from 'antd';

const ContactUs: React.FC = () => {
  return (
    <div>
      <div className="card">
        <div className="flex justify-center items-center flex-col my-5">
          <Typography.Title level={2} className="title red">
            Kontakt
          </Typography.Title>
        </div>
        <div className="mt-5 max-w-xl self-center">
          <Typography.Title level={4} className="red text-3xl">
            Kom i kontakt med oss: <br />
            Via mail: teezy.uf@hotmail.com <br />
            Via instagram: teezy_uf <br />
            Address: Roslagstullsbacken 35, 114 21 Stockholm
          </Typography.Title>
        </div>
        <div className="mt-5 max-w-xl self-center">
          <Typography.Title level={4} className="red text-3xl">
            Följ oss på våra sociala medier for att ta del av nyheter och
            uppdateringar!
          </Typography.Title>
        </div>
        <div className="flex w-full align-center justify-center mt-10 flex-col">
          <div className="flex align-center justify-center">
            <SocialIcon url="http://instagram.com/teezy_uf" className="mx-2" />
            <SocialIcon url="http://tiktok.com/teezy_uf" className="mx-2" />
          </div>
          <div className="flex align-center justify-center mt-3">
            <Typography.Title level={4} className="red text-3xl">
              teezy_uf
            </Typography.Title>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
