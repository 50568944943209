import { useQuery } from '@tanstack/react-query';

import { getProducts, getProduct } from 'api/product';
import { GetProductResponse, GetProductsResponse } from 'api/types';

export const useGetProducts = () => {
  return useQuery({
    queryKey: [getProducts.name],
    queryFn: async (): Promise<GetProductsResponse> => {
        return (await getProducts()).data;
    },
  });
};
export const useGetProductById = (id: string) => {
  return useQuery({
    queryKey: [getProduct.name, id],
    queryFn: async (): Promise<GetProductResponse> => {
        return (await getProduct(id)).data;
    },
    enabled: !!id,
  });
};
