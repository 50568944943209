export const ENDPOINT_BASE = window.__ENDPOINT_BASE__;

export const LAYOUT = { labelCol: { span: 6 }, wrapperCol: { span: 16 } };
export const LAYOUT_TAIL = { wrapperCol: { offset: 6, span: 16 } };

export const links = [
  {
    path: '/',
    label: 'HEM',
  },
  {
    path: '/products',
    label: 'Produkter',
  },
  {
    path: '/gallery',
    label: 'Galleri',
  },
  {
    path: '/about-us',
    label: 'Om Oss',
  },
  {
    path: '/contact',
    label: 'Kontakt',
  },
];