import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { StyleProvider } from '@ant-design/cssinjs';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Drawer, Typography } from 'antd';

import Layout from 'components/Layout';
import HomeView from 'views/Home';
import ProductsView from 'views/Products';
import ProductView from 'views/Product';
import GalleryView from 'views/Gallery';
import ContactUsView from 'views/ContactUs';
import AboutUsView from 'views/AboutUs';

import CartView from 'views/Cart';

const App: React.FC = () => {
  const [fullSizeModalOpened, setFullSizeModalOpened] = React.useState(false);

  const handleOpenCart = () => setFullSizeModalOpened(true);
  const handleCloseCart = () => setFullSizeModalOpened(false);

  return (
    <div className="transition contents">
      <StyleProvider hashPriority="high">
        <Layout handleOpenCart={handleOpenCart}>
          <Routes>
            <Route path="/" element={<HomeView />} />
            <Route path="/products" element={<ProductsView />} />
            <Route
              path="/product/:id"
              element={<ProductView openCart={handleOpenCart} />}
            />
            <Route path="/gallery" element={<GalleryView />} />
            <Route path="/contact" element={<ContactUsView />} />
            <Route path="/about-us" element={<AboutUsView />} />
          </Routes>
        </Layout>
        <Drawer
          open={fullSizeModalOpened}
          destroyOnClose
          onClose={handleCloseCart}
          extra={
            <div>
              <Typography.Title level={4} className="red" style={{ margin: 0 }}>
                Varukorg
              </Typography.Title>
            </div>
          }
          width="100VW"
          closeIcon={
            <ArrowLeftOutlined
              style={{ color: '#820014' }}
              height={56}
              size={56}
            />
          }
        >
          <CartView closeModal={handleCloseCart} />
        </Drawer>
      </StyleProvider>
    </div>
  );
};

export default App;
