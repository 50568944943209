import axios, { AxiosResponse } from 'axios';

import { ENDPOINT_BASE } from 'constants/index';
import { CartResponse, CreateCartRequest, UpdateCartRequest } from 'api/types';

const CART_PATH = 'cart';
const baseURL = `${ENDPOINT_BASE}${CART_PATH}`;

const client = axios.create({
  baseURL,
});

export async function createCart(
  request: CreateCartRequest
): Promise<AxiosResponse<CartResponse>> {
  const response = await client.post<CartResponse>('', request);

  return response;
}

export async function updateCart(
  request: UpdateCartRequest
): Promise<AxiosResponse<CartResponse>> {
  const response = await client.put<CartResponse>('', request);

  return response;
}

export async function getCart(
  id: string
): Promise<AxiosResponse<CartResponse>> {
  const response = await client.get<CartResponse>(`/${id}`);

  return response;
}
