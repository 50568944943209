import React from 'react';

import { Typography } from 'antd';

const AboutUs: React.FC = () => {
  return (
    <div>
      <div className="card">
        <div className="flex items-center w-full justify-center flex-col">
          <Typography.Title level={2} className="title red" color="red-5">
            Om Oss
          </Typography.Title>

          <Typography.Title level={2} className="title red">
            Teezy UF
          </Typography.Title>
        </div>

        <div className="border border-red-800 p-5 mt-10 max-w-xl self-center">
          <Typography.Text className="red text-3xl">
            Vi är tre tjejer som driver företaget Teezy uf. Vår vision är att
            ta fram nytänkande unika designer till T-shirts. Designerna kan vara
            allt från coola tryck till snygga jeans-detaljer. Affärsidén bygger
            på att skapa unika t-shirts som du kan ha på dig var som helst, när
            som helst och samtidigt känna dig bekväm och utstråla
            självförtroende. Här på Teezy uf satsar vi helhjärtat på kvalitet och
            tar fram det där lilla extra med t-shirten, allt för att ge
            vardagen lite mer lyx.
          </Typography.Text>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
