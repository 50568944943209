import React from 'react';
import classnames from 'classnames';
import { Popover, Typography, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useOnClickOutside } from 'usehooks-ts';
import { useIsDesktop } from 'hooks';

import { links } from 'constants/index';

import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';

interface Props {
  handleToggleIsOpened: () => void;
  isOpened: boolean;
  handleOpenCart: () => void;
}

const SvgIcon = ({ isOpened }: { isOpened: boolean }) => (
  <>
    <svg
      id="toggleSidebarMobileHamburger"
      className={classnames('w-6', 'h-6', { hidden: isOpened })}
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        color="#820014"
        fillRule="evenodd"
        d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
        clipRule="evenodd"
      ></path>
    </svg>
    <svg
      id="toggleSidebarMobileClose"
      className={classnames('w-6', 'h-6', { hidden: !isOpened })}
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        color="#820014"
        fillRule="evenodd"
        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
        clipRule="evenodd"
      ></path>
    </svg>
  </>
);

const Header: React.FC<Props> = ({
  handleToggleIsOpened,
  isOpened,
  handleOpenCart,
}) => {
  const popoverSpaceRef = React.useRef(null);
  const menuItemRef = React.useRef(null);

  const isDesktop = useIsDesktop();

  const handleClickOutside = () => {
    if (isOpened && !isDesktop) {
      handleToggleIsOpened();
    }
  };

  const navigate = useNavigate();

  const handleChangeRoute = (route: string) => () => {
    navigate(route);
    if (isOpened) {
      handleToggleIsOpened();
    }
  };

  useOnClickOutside([popoverSpaceRef, menuItemRef], handleClickOutside);

  return (
    <nav className="bg-white border-b border-gray-200 fixed z-40 w-full">
      <div className="px-3 py-3 lg:px-5 lg:pl-3">
        <div className="flex items-center justify-end">
          <div className="flex items-center justify-between flex-1">
            <a
              href="/"
              className="text-xl font-bold flex items-center lg:ml-2.5"
            >
              {/* <span className="self-center whitespace-nowrap mx-2 text-black"></span> */}
              <Typography.Title level={2} className="title red">
                Teezy UF
              </Typography.Title>
            </a>
            <div className="flex">
              <button
                onClick={handleOpenCart}
                aria-expanded="true"
                aria-controls="sidebar"
                className="mr-2 text-gray-600 hover:text-gray-900 cursor-pointer p-2 hover:bg-gray-100 focus:bg-gray-100 focus:ring-2 focus:ring-gray-100 rounded"
              >
                <ShoppingBasketIcon color="primary" />
              </button>
              {isDesktop ? (
                <button
                  onClick={handleToggleIsOpened}
                  ref={popoverSpaceRef}
                  id="toggleSidebarMobile"
                  aria-expanded="true"
                  aria-controls="sidebar"
                  className="transition mr-2 text-gray-600 hover:text-gray-900 cursor-pointer p-2 hover:bg-gray-100 focus:bg-gray-100 focus:ring-2 focus:ring-gray-100 rounded"
                >
                  <SvgIcon isOpened={isOpened} />
                </button>
              ) : (
                <Popover
                  content={
                    <Space
                      ref={popoverSpaceRef}
                      direction="vertical"
                      size="middle"
                      style={{ display: 'flex', minWidth: '160px' }}
                    >
                      {links.map((link) => (
                        <Typography.Link
                          onClick={handleChangeRoute(link.path)}
                          style={{ color: '#820014', fontSize: 16 }}
                        >
                          {link.label}
                        </Typography.Link>
                      ))}
                    </Space>
                  }
                  // trigger="click"
                  open={isOpened}
                  placement="bottomRight"
                  destroyTooltipOnHide={false}
                >
                  <button
                    ref={menuItemRef}
                    onClick={handleToggleIsOpened}
                    id="toggleSidebarMobile"
                    aria-expanded="true"
                    aria-controls="sidebar"
                    className="transition lg:hidden mr-2 text-gray-600 hover:text-gray-900 cursor-pointer p-2 hover:bg-gray-100 focus:bg-gray-100 focus:ring-2 focus:ring-gray-100 rounded"
                  >
                    <SvgIcon isOpened={isOpened} />
                  </button>
                </Popover>
              )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
