import { useQuery, useMutation, UseMutateFunction, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { getCart, createCart, updateCart } from 'api/cart';
import { CartResponse, CreateCartRequest, UpdateCartRequest } from 'api/types';
import { AxiosError } from 'axios';

export const useGetCart = (id?: string) => {
  return useQuery({

    queryKey: [getCart.name, id],
    queryFn: async (): Promise<CartResponse> => {
      try{
        return (await getCart(id!)).data;
      }catch(err){
        //console.error(err)
        if(err instanceof AxiosError && err.status === 404) {
          console.warn("Cart not found, removing cart");
          sessionStorage.removeItem('cartId');
          throw err
        }
        throw err
      }
    },
    enabled: !!id,
  });
};

export const useCreateCart = (): [
  UseMutateFunction<CartResponse, Error, CreateCartRequest, unknown>,
  'error' | 'idle' | 'pending' | 'success',
] => {
  const {
    mutate,
    status,
  }: {
    mutate: UseMutateFunction<CartResponse, Error, CreateCartRequest, unknown>;
    status: 'error' | 'idle' | 'pending' | 'success';
  } = useMutation({
    mutationFn: async (request: CreateCartRequest): Promise<CartResponse> => {
      return (await createCart(request)).data;
    },
    onError: () => {
      message.error('Something went wrong. Error creating cart.');
    },
    onSuccess: (response) => {
      sessionStorage.setItem('cartId', response.id);
    }
  });

  return [mutate, status];
};

export const useUpdateCart = (): [
  UseMutateFunction<CartResponse, Error, UpdateCartRequest, unknown>,
  'error' | 'idle' | 'pending' | 'success',
] => {

  const queryClient = useQueryClient();
      

  const {
    mutate,
    status,
  }: {
    mutate: UseMutateFunction<CartResponse, Error, UpdateCartRequest, unknown>;
    status: 'error' | 'idle' | 'pending' | 'success';
  } = useMutation({
    mutationFn: async (request: UpdateCartRequest): Promise<CartResponse> => {
      return (await updateCart(request)).data;
    },
    onSettled: async (_,__,variables) => {
      await queryClient.invalidateQueries({ queryKey: [getCart.name, variables.id] });
    },
    onError: () => {
      message.error('Something went wrong. Error updating cart.');
    },
  });

  return [mutate, status];
};
