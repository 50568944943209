import React, { useCallback, useState } from 'react';

import { useIsDesktop } from 'hooks';
import classNames from 'classnames';

import Footer from './Footer';
import Header from './Header';
import Sidebar from './Sidebar';

import { links } from 'constants/index';

interface ChildProp {
  children: React.ReactNode;
  handleOpenCart: () => void;
}

const Layout: React.FC<ChildProp> = ({ children, handleOpenCart }) => {
  const isDesktop = useIsDesktop();

  const [isOpened, setIsOpened] = useState(false);

  const handleToggleIsOpened = useCallback(() => {
    setIsOpened((value) => !value);
  }, [setIsOpened]);

  return (
    <>
      <Header
        handleToggleIsOpened={handleToggleIsOpened}
        isOpened={isOpened}
        handleOpenCart={handleOpenCart}
      />
      <div className="flex bg-white pt-16 w-full">
        {isDesktop ? (
          <Sidebar
            handleToggleIsOpened={handleToggleIsOpened}
            links={links}
            isOpened={isOpened}
          />
        ) : null}
        <div
          className={classNames(
            'bg-gray-900',
            'opacity-50',
            'fixed',
            'inset-0',
            'z-10',
            'lg:hidden',
            { hidden: !isOpened || !isDesktop }
          )}
          id="sidebarBackdrop"
          onClick={handleToggleIsOpened}
        ></div>
        <div id="main-content" className="h-full bg-gray-50 relative">
          <main style={{ width: '100vw', maxWidth: '100vw' }}>{children}</main>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Layout;
